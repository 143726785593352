
body {
    margin: 0;
    background-color: #fff;
    font-family: Rubik, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-color: var(--black-alpha-35);
}

.img-container {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  padding-bottom: .5rem;
}
.img-container .providerImage {
  max-height: 65px;
  object-fit: cover;
}

.container{
  width: 100%;
}

.content{
  width: 100%;
  height: 100%;
  flex: auto;
}

.filter-dialog-container {
  position: absolute !important;
  top: 5rem;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw !important;
  width: 100%;
}

.filter-dialog-container .mat-dialog-container {
    overflow: visible;
    border-radius: 40px 40px 0 0;
}

.mat-autocomplete-panel.doctor-search-panel {
  border-radius: 0px 0px 25px 25px !important;
  box-shadow: 0px 4px 20px rgb(139 141 160 / 25%) !important;
  background: #3C3C53;
  padding-top: 1.75rem;
  margin-top: -1.5rem;
}

.mat-autocomplete-panel.doctor-search-panel .mat-option {
  height: 77px;
}

.mat-autocomplete-panel.custom-panel {
  padding-top: 1rem;
  margin-top: -1rem;
  border: 1px solid var(--gray-base-color);
  border-top: none;
  border-radius: 0 0 1.45rem 1.45rem;
  box-shadow: none !important;
}
.mat-autocomplete-panel.custom-panel.provider-select .mat-option {
  border-bottom: 1px solid var(--gray-lighter-color);
}
@media screen and (min-width: 600px) {
    .filter-dialog-container {
        position: static !important;
        max-width: 80vw !important;
        width: fit-content;
    }

    .filter-dialog-container .mat-dialog-container {
      border-radius: 40px;
    }
}

.custom-dialog-container .mat-dialog-container{
    border-radius: 20px !important;
    background-color: var(--dark-base-color) !important;
    color: white !important;
    word-break: break-word;
}

.continue-button{
    width: 100%;
    height: 3rem;
    background: var(--accent-base-color);
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05)!important;
    border-radius: 30px!important;
    margin: auto!important;
    background-repeat: no-repeat!important;
    background-size: 25px!important;
    background-position-y: center!important;
    background-position-x: 96%!important;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.2rem;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-raised-button,
.mat-flat-button {
    height: 3rem;
    max-height: 60px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05) !important;
    border-radius: 30px !important;
}

.mat-flat-button,
.mat-stroked-button {
    line-height: 3rem !important;
}

.mat-icon[svgIcon="lad"] {
    font-size: 2rem;
    height: 2rem;
    width: 72px;
}

button[color="dark"] {
  background: var(--dark-base-color);
  color: var(--primary-base-color);
}

.mat-button-base .mat-icon.east {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: normal;
}

.stepText{
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.2rem;
}
.mat-form-field :ng-deep .mat-form-field-flex{
    height: 48px!important;
}

@media(max-width: 600px){
    .stepper{
        width: 70%;
    }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.fade-out {
  animation-name: fadeOut;
}

.animated.fade-in {
  animation-name: fadeIn;
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animated[class*='out'] {
    opacity: 0;
  }
}

.mat-snack-bar-handset .mat-snack-bar-container.toast {
  margin: 58px 2px 0;
}

.mat-snack-bar-container.toast {
  display: flex;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(196, 196, 196, 0.67);
  border-radius: 10px;
  margin: 66px .25rem 0;
  --success-bg-color: #F2FDCF;
  --success-text-color: #047B09;
  --error-bg-color: #FFE1DB;
  --error-text-color: #F96041;
  --warning-bg-color: #FBF0DA;
  --warning-text-color: #DF9B0C;
  --info-bg-color: #EFE9F7;
  --info-text-color: #7878F7;
}

.toast span::before {
  direction: ltr;
  font-family: 'Material Icons Outlined';
  font-size: 1.5rem;
  float: left;
  margin-right: .25rem;
}

.toast.success {
  border-color: var(--success-text-color);
  background-color: var(--success-bg-color);
  color: var(--success-text-color);
}

.toast.success span::before {
  content: "\e86c";
}

.toast.success .mat-button {
  color: var(--success-text-color);
}

.toast.error {
  border-color: var(--error-text-color);
  background-color: var(--error-bg-color);
  color: var(--error-text-color);
}

.toast.error span::before {
  content: "\e5c9";
}

.toast.error .mat-button {
  color: var(--error-text-color);
}

.toast.info {
  border-color: var(--info-text-color);
  background-color: var(--info-bg-color);
  color: var(--info-text-color);
}

.toast.info span::before {
  content: "\e001";
}

.toast.info .mat-button {
  color: var(--info-text-color);
}

.toast.warning {
  border-color: var(--warning-text-color);
  background-color: var(--warning-bg-color);
  color: var(--warning-text-color);
}

.toast.warning span::before {
  content: "\e88e";
}

.toast.warning .mat-button {
  color: var(--warning-text-color);
}

/**
* CONFETTI
**/

.auth-confetti {
  background-image: url("/assets/icons/auth-confetti-sm.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right 3rem;
  overflow: visible;
}

@media screen and (min-width: 600px) {
  .auth-confetti {
    background-image: none;
  }

  .auth-confetti .right-content,
  .auth-confetti .right-confetti {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 6rem;
    background-image: url("/assets/icons/auth-right-confetti-md.svg");
  }
}

@media screen and (min-width: 1024px) {
  .auth-confetti {
    background-image: none;
  }

  .auth-confetti .right-content,
  .auth-confetti .right-confetti {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 6rem;
    background-image: url("/assets/icons/auth-right-confetti-lg.svg");
  }
}

/**/

.OT_fit-mode-cover .OT_video-element {
    object-fit: contain!important;
}

/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
	display: block !important;
  height: 44px!important;
}

.iti__selected-flag {
  border-radius: 28px 0 0 28px;
}

.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
}

.iti .iti__country-list {
	box-shadow: none;
	font-size: 14px;
	margin-left: 0;
	width: 244px;
	max-height: 170px;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	font-size: 14px;
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
  border-radius: 28px 28px 0 0;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
	margin-bottom: 20px;
	border-style: solid;
	border-width: 1px;
	border-color: var(--input-border-color);
	border-radius: 28px;
	font-size: 18px;
  width: 100%;
  height: 44px!important;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
	border: 2px solid red
}

.iti input:hover {
	border-width: 2px;
  border-color: black;
}

.iti input:focus {
	outline: none !important;
  border-width: 2px;
	border-color: var(--primary-base-color);
	box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}

.loading-spinner-container {
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  background: rgba(41, 41, 59, 1);
}

.loading-spinner {
  width: 150px;
  height: 150px;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.spinner div {
  box-sizing: content-box;
}

@keyframes loading-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loading-spinner div {
  left: 72px;
  top: 30px;
  position: absolute;
  animation: loading-spinner linear 1.5873015873015872s infinite;
  background: var(--accent-base-color);
  width: 6px;
  height: 18px;
  border-radius: 3px / 4.140000000000001px;
  transform-origin: 3px 45px;
}

.loading-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.455026455026455s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.3227513227513226s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -1.1904761904761905s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -1.0582010582010581s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.9259259259259258s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.7936507936507936s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.6613756613756613s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.5291005291005291s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3968253968253968s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.26455026455026454s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.13227513227513227s;
  background: var(--accent-base-color);
}

.loading-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: var(--accent-base-color);
}
